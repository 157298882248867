<template>
  <v-container fluid>
    <data-table modelName="eventSource">
      <template #[`actions`]="{ item }">
        <v-btn
          small
          text
          :to="{ name: 'events', query: { eventSource: item._id } }"
        >
          {{ $vuetify.lang.t(`$vuetify.pages.eventSources.viewEvents`) }}
        </v-btn>
      </template>
    </data-table>
  </v-container>
</template>

<script>
import DataTable from "@/components/DataTable";

export default {
  components: {
    DataTable
  }
};
</script>

<style lang="scss" scoped></style>
